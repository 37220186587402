import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import rules from './rules';

export default {
  install(Vue) {
    Object.keys(rules).forEach(rule => {
      extend(rule, rules[rule]);
    });
    
    Vue.component('ValidationObserver', ValidationObserver);
    Vue.component('ValidationProvider', ValidationProvider);
  }
}