<template>
  <div 
    class="color-picker"
    @click="() => { if (!disabled) $emit('input', color) }" 
    :class="{ active }"
    :disabled="disabled"
    :style="{ background: color }"
  ></div>
</template>

<script>
export default {
  props: {
    color: String,
    value: String,
    disabled: Boolean
  },
  computed: {
    active() {
      return this.color === this.value
    }
  }
}
</script>