var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-control",class:{
    'has-icon': _vm.icon,
    'focused': _vm.isFocused,
    'disabled': _vm.disabled,
    'i18n': _vm.i18n,
    'has-error': _vm.error
  }},[(_vm.i18n)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentLocale),expression:"currentLocale"}],staticClass:"locale-picker",attrs:{"tabindex":"-1"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.currentLocale=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.locales),function(locale){return _c('option',{key:locale.key,domProps:{"value":locale.key,"selected":locale.default}},[_vm._v(_vm._s(locale.key))])}),0):_vm._e(),_c('input',_vm._b({attrs:{"type":_vm.type,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"step":_vm.step},domProps:{"value":_vm.computedValue},on:{"input":_vm.onInput,"focus":_vm.onFocus,"blur":_vm.onBlur}},'input',_vm.$attrs,false)),(_vm.icon)?_c('div',{staticClass:"icon"},[_vm._t("icon")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }