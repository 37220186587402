<template>
  <div 
    class="checkbox" 
    :class="{
      'checked': computedValue,
      'focused': focused,
      'disabled': disabled
    }"
  >
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M16 4.907l-6.78 8.52a1.516 1.516 0 01-2.225.16L2 8.88l2.077-2.227 3.797 3.578L13.628 3 16 4.907z" fill="#000"/></svg>
    <input 
      type="checkbox" 
      v-model="computedValue"
      :disabled="disabled"
      @focus="() => focused = true"
      @blur="() => focused = false"
    >
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
    disabled: Boolean
  },
  data() {
    return {
      newValue: this.value,
      focused: false
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue
      },
      set(value) {
        this.newValue = value
        this.$emit('input', value)
      }
    }
  },
  watch: {
    value(value) {
      this.newValue = value
    }
  }
}
</script>